{
  "name": "fooswars",
  "version": "6.24.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4258",
    "build": "ng build --configuration production",
    "test": "ng test",
    "lint": "ng lint --quiet",
    "e2e": "ng e2e",
    "analyze": "ng build --configuration production --stats-json && webpack-bundle-analyzer dist/stats.json",
    "preinstall": "npx only-allow pnpm",
    "format": "prettier --write .",
    "check-format": "prettier --check ."
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.1",
    "@angular/cdk": "^19.2.2",
    "@angular/common": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/material": "^19.2.2",
    "@angular/material-luxon-adapter": "^19.2.2",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/platform-server": "^19.2.1",
    "@angular/router": "^19.2.1",
    "@angular/service-worker": "^19.2.1",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@microsoft/signalr": "^8.0.7",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/operators": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@sweetalert2/ngx-sweetalert2": "^13.0.0",
    "@swimlane/ngx-charts": "^21.1.3",
    "@types/luxon": "^3.4.2",
    "bootstrap": "^5.3.3",
    "d3-shape": "^3.2.0",
    "luxon": "^3.5.0",
    "ngx-image-cropper": "^9.1.2",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-translate-messageformat-compiler": "^7.1.0",
    "rxjs": "^7.8.2",
    "sweetalert2": "^11.17.2",
    "tslib": "^2.6.2",
    "ua-parser-js": "^2.0.2",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular-eslint/builder": "^19.2.1",
    "@angular-eslint/eslint-plugin": "^19.2.1",
    "@angular-eslint/eslint-plugin-template": "^19.2.1",
    "@angular-eslint/schematics": "^19.2.1",
    "@angular-eslint/template-parser": "^19.2.1",
    "@angular/cli": "^19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@angular/language-service": "^19.2.1",
    "@ngrx/schematics": "^19.0.1",
    "@types/d3-shape": "^3.1.7",
    "@types/jasmine": "^5.1.7",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^22.13.10",
    "@types/ua-parser-js": "^0.7.39",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "@typescript-eslint/parser": "^8.26.1",
    "eslint": "^9.22.0",
    "eslint-config-prettier": "^10.1.1",
    "eslint-plugin-prettier": "^5.2.3",
    "jasmine-core": "~5.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-cli": "~2.0.0",
    "karma-coverage": "^2.2.1",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "prettier": "^3.5.3",
    "prettier-eslint": "^16.3.0",
    "typescript": "~5.5.3",
    "webpack-bundle-analyzer": "^4.10.2"
  },
  "pnpm": {
    "onlyBuiltDependencies": [
      "esbuild",
      "lmdb",
      "msgpackr-extract"
    ]
  }
}
